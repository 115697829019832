import React from 'react';
import { TextField, Box } from '@popmenu/common-ui';
import { useField } from 'react-form';
import { validate } from '~/utils/fieldValidators';

const dateToValue = (date: Date) => {
  const pad = (num: number) => num.toString().padStart(2, '0');
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const DateTimeLocaleInput = (props: {advancedFormField: Record<string, unknown>}) => {
  const now = new Date();
  const defaultValue = dateToValue(now);
  const fieldName = `field_${props.advancedFormField.id as string}`;
  const field = useField<string>(fieldName, { defaultValue: now.toISOString(), validate: validate({ required: true }) });
  const { value, onBlur } = field.getInputProps();
  return (
    <Box mb={3}>
      <TextField
        id={`${fieldName}-input`}
        type="datetime-local"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          'aria-label': props.advancedFormField.placeholder as string,
        }}
        onBlur={onBlur}
        onChange={e => field.setValue(new Date(e.target.value).toISOString())}
        value={dateToValue(new Date(value))}
        defaultValue={defaultValue}
      />
    </Box>
  );
};
