import { lighten } from '@material-ui/core/styles';
import { readableFontColor } from '../../../utils/colors';

export default (theme) => {
  const whiteBackground = { backgroundColor: 'white' };

  const getColorStyle = (props) => {
    let color = '#000';
    if (props?.fontColor) {
      color = props.fontColor;
    } else if (props?.backgroundColor) {
      color = readableFontColor(props.backgroundColor);
    }
    return { color };
  };

  return {
    advancedFormName: props => ({
      ...getColorStyle(props),
      marginBottom: '24px',
    }),
    checkBoxPicker: props => ({
      '& .MuiIconButton-label': getColorStyle(props),
    }),
    customCheckboxOption: {
      marginBottom: `${theme.spacing(1)}px`,
    },
    customDateTimePicker: {
      ...whiteBackground,
      width: '50%',
    },
    customDesignElement: props => ({
      ...getColorStyle(props),
      marginBottom: theme.spacing(3),
    }),
    customFileUpload: {
      '& input': {
        paddingBottom: '24px',
        paddingTop: '22px',
      },
      ...whiteBackground,
    },
    customSelect: whiteBackground,
    customSelectInput: {},
    customTextArea: whiteBackground,
    customTextField: {
      ...whiteBackground,
      marginBottom: '42px',
    },
    divider: {
      marginBottom: theme.spacing(3),
      marginTop: -theme.spacing(3),
    },
    dropdownPicker: whiteBackground,
    fieldLabel: getColorStyle,
    formButton: {
      '& button': {
        backgroundColor: theme.palette.primary.main,
      },
      '& button:disabled': {
        backgroundColor: lighten('#000', 0.8),
      },
    },
    locationPicker: whiteBackground,
    multipleChoice: props => ({
      '& .MuiIconButton-label': getColorStyle(props),
    }),
    multipleChoiceContainer: {
      marginBottom: '42px',
    },
    spacer: {
      height: '56px',
    },
  };
};
