import type { FieldApi, ValidateResult } from 'react-form';

/**
 * README
 * fieldValidators primary export is the `validate` function. `validate` is meant to be used for a react-form field.
 * `validate` can handle multiple validations and composes all the other utilities in this file.
 *
 * A lot of the utilities in this file are similar to utilities in `./forms.ts`. The difference, and reason for making
 * these utilities, is that the older validations return error strings formatted as content. The problem with that
 * is error messages are difficult to change and difficult to localize. This `validate` function returns the name
 * of an error which can be used to map to custom error messages and messages created by react-intl.
 *
 * react-form validation docs: https://github.com/TanStack/form/blob/react-form-deprecated/docs/validation.md
 */

// react-form validation functions should return false when the field validation passes, variable helps readability.
const valid = false;

type Validator = (value: unknown, field: FieldApi<unknown>, validatorArg?: unknown) => ValidateResult
type Validation = keyof typeof validators
type ValidationRecord = Partial<Record<Validation, unknown>>
interface ValidateOptions extends ValidationRecord {
  required?: boolean
  emailFormat?: boolean
  phoneFormat?: boolean
  greaterThan?: number
  lessThan?: number
  between?: [number, number]
}

const formats = {
  email: /\b(?<username>[A-Z0-9._%+-])+@(?<domain>[A-Z0-9.-])+\.(?<TLD>[A-Z]{2,})\b/i, // user@domain.TLD
  phone: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, // xxx-xxx-xxxx, xxx xxx xxxx, +xxxxxxxxxx, xxxxxxxxxx, +xx (xxx) xxx xxxx, (xxx) xxx-xxxx, (xxx)xxxxxxx
};

/** VALIDATORS */
export const toBeImplemented: Validator = () => valid;

export const required: Validator = (value) => {
  const error = 'required';
  if (typeof value !== 'string') return error;

  const invalid = !value || String(value).trim().length === 0;
  return invalid ? error : valid;
};

export const emailFormat: Validator = (value) => {
  const error = 'emailFormat';
  if (typeof value !== 'string') return error;

  const match = formats.email.test(value);
  const invalid = !value || !match;
  return invalid ? error : valid;
};

export const phoneFormat: Validator = (value) => {
  const error = 'phoneFormat';
  if (typeof value !== 'string') return error;

  const match = formats.phone.test(value);
  const invalid = !value || !match;
  return invalid ? error : valid;
};

export const greaterThan: Validator = (value, _field, min) => {
  const error = 'greaterThan';
  if (typeof value !== 'string') return error;
  if (typeof min !== 'number' || !value) return null;

  const invalid = Number(value) <= min;
  return invalid ? error : valid;
};

export const validators = {
  between: toBeImplemented,
  emailFormat,
  greaterThan,
  lessThan: toBeImplemented,
  phoneFormat,
  required,
};

/**
 * runs all validators specified in options arg.
 * returns the first error found, will not needlessly run other validations
 * runs the 'required' validator first if specified, since the required error should display before any other error
 * the switch statement is used so that types of option values and validator arg can match (instead of dynamic accessors ie. validators[optionName](value, field, options[optionName])
 */
export const validate = (options: ValidateOptions) => (value: unknown, field: FieldApi<unknown>) => field.debounce(() => {
  field.setMeta({ error: false });
  const optionKeys = Object.keys(options) as Validation[];
  const secondaryError = optionKeys.find((optionKey) => {
    switch (optionKey) {
      case 'between':
        return validators.between(value, field, options.between) !== valid;
      case 'emailFormat':
        return validators.emailFormat(value, field, options.emailFormat) !== valid;
      case 'greaterThan':
        return validators.greaterThan(value, field, options.greaterThan) !== valid;
      case 'lessThan':
        return validators.lessThan(value, field, options.lessThan) !== valid;
      case 'phoneFormat':
        return validators.phoneFormat(value, field, options.phoneFormat) !== valid;
      default:
        return valid;
    }
  });
  const primaryError = options.required ? validators.required(value, field) : null;
  return primaryError || secondaryError || valid;
}, 500);
