import type { ValidatorProps } from './interfaces';

export const checkBoxNotBlankValidator: ValidatorProps = fieldName => (_value, instance) => {
  const groupValues = instance.form.values[fieldName] || '';
  const hasSelectedOption = typeof groupValues === 'string' && groupValues.trim() !== '';

  if (hasSelectedOption) {
    Object.keys(instance.form.values[`_${fieldName}`] || {}).forEach((optionField) => {
      instance.form.setFieldMeta(`_${fieldName}.${optionField}`, { error: null });
    });
  } else {
    instance.form.setFieldMeta(instance.fieldName, { error: hasSelectedOption ? null : 'Cannot be blank' });
  }
};
